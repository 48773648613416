<template>
  <breakout-history-template
    :treated-for-acne="treatedForAcne"
    :acne-treatments="acneTreatments"
    :gender="gender"
    :is-treated-for-acne="isTreatedForAcne"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import BreakoutHistoryTemplate from '@/modules/questionnaire/components/steps/common/medical-background/breakout-history/BreakoutHistoryTemplate.vue';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes, no } from '@/modules/questionnaire/api/helpers';

const UNSURE = 'unsure';

const { requiredField, requiredArrayField, field } = fieldBuilder;

const FIELDS = [
  field('gender'),
  requiredField('treatedForAcne'),
  requiredArrayField('acneTreatments')
];

export default {
  name: 'BreakoutHistory',
  components: {
    BreakoutHistoryTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    isTreatedForAcne() {
      return yes(this.treatedForAcne);
    }
  },
  watch: {
    treatedForAcne(newValue) {
      if (no(newValue)) {
        this.acneTreatments = [];
        this.showNextStep();
      } else {
        this.scrollTo('#acne-treatments');
      }
    },
    acneTreatments(newValue) {
      if (newValue && newValue.length === 1 && newValue[0] === UNSURE) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'acneTreatments') {
        return this.isTreatedForAcne;
      }

      return true;
    }
  }
};
</script>
